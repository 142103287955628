import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private readonly url = 'iam-report/sellers/dashboard';
  private readonly urlUserInactive = environment.baseTncnServiceURL + '/user-inactive';

  constructor(private http: HttpClient) {
  }

  getIssuedQuantity(fromDate: number, thruDate: number): Observable<any> {
    return this.http.get(`${this.url}/invoices/issued-quantity`, {params: {fromDate, thruDate}});
  }

  getIssuedAmount(fromDate: number, thruDate: number): Observable<any> {
    return this.http.get(`${this.url}/invoices/issued-amount`, {params: {fromDate, thruDate}});
  }

  getRemainQuantity(fromDate?: number, thruDate?: number): Observable<any> {
    let params = {};
    if (fromDate && thruDate) {
      params = {fromDate, thruDate};
    }
    return this.http.get(`${this.url}/invoices/remain-quantity`, {params});
  }

  getIssuedQuantityPeriod(lastNMonth: number): Observable<any> {
    return this.http.get(`${this.url}/invoices/issued-quantity-period?lastNMonth=${lastNMonth}`);
  }

  getAmountByParties(fromDate: number, thruDate: number): Observable<any> {
    return this.http.get(`${this.url}/invoices/amount-by-parties`, {params: {fromDate, thruDate}});
  }

  getClassTypeStat(fromDate: number, thruDate: number): Observable<any> {
    return this.http.get(`${this.url}/invoices/class-type-stat`, {params: {fromDate, thruDate}});
  }
  findUserInactive(item:any ): Observable<any> {
    return this.http.get(`${this.urlUserInactive}/${item}`);
  }
}
